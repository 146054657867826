import { Component } from '@angular/core';

import { bitfToTranslate } from '@common/libs/bitforce/utils/bitf-translate.utils';

import { IHeroDropdownItem, IMenuItem } from '@common/interfaces';

import { AuthService } from '@web/core/services';

@Component({
  selector: 'hero-desktop-admin-layout',
  templateUrl: './desktop-admin-layout.component.html',
  styleUrls: ['./desktop-admin-layout.component.scss'],
})
export class DesktopAdminLayoutComponent {
  menuItems: IMenuItem[] = [
    {
      label: bitfToTranslate('SIDEBAR.BUTTON.CAMPAIGNS'),
      url: '/campaigns',
      icon: 'stack-line',
    },
    {
      label: bitfToTranslate('SIDEBAR.BUTTON.INSIGHTS'),
      url: '/insights',
      icon: 'donut-chart-fill',
    },
    {
      label: bitfToTranslate('SIDEBAR.BUTTON.CLIENTS'),
      url: '/clients',
      icon: 'vip-crown-line',
    },
    {
      label: bitfToTranslate('SIDEBAR.BUTTON.ALL_SETTINGS'),
      url: '/ai-settings',
      icon: 'git-branch-fill',
    },
    {
      label: bitfToTranslate('SIDEBAR.BUTTON.ACCOUNTS'),
      url: '/accounts',
      icon: 'user-settings-line',
    },
  ];

  accountMenuItems: IHeroDropdownItem[] = [
    {
      label: bitfToTranslate('HEADER.BUTTON.MY_SETTINGS'),
      value: 'settings',
      url: '/settings',
    },
    {
      label: bitfToTranslate('HEADER.BUTTON.LOGOUT'),
      value: 'logout',
      func: () => {
        this.authService.signOut();
      },
    },
  ];

  constructor(private authService: AuthService) {}

  updateLayout() {}
}
