<div class="container-fluid">
  <div class="row">
    <div class="col col-sidebar">
      <hero-sidebar [menuItems]="menuItems"></hero-sidebar>
    </div>
    <div class="col sidebar-enabled navbar-padding">
      <div class="row">
        <hero-header [accountMenuItems]="accountMenuItems"></hero-header>
      </div>
      <div class="row">
        <div class="col">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>