import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hero-radio-box',
  templateUrl: './radio-box.component.html',
  styleUrls: ['./radio-box.component.scss'],
})
export class RadioBoxComponent implements OnInit {
  @Input() label: string;
  @Input() value: string | number;
  @Input() description: string;
  @Input() className: string;
  @Input() name: string;

  constructor() {}

  ngOnInit(): void {}
}
