<div *ngIf="items.length"
  class="dropdown"
  [ngClass]="className"
  [class.dropdown--disabled]="isDisabled"
  (bitfClickOutside)="isOpen = false">
  <div *ngIf="!enableSearch"
    (click)="toggleDropdown()"
    class="dropdown-toggle"
    [class.placeholder]="!selectedItem">{{ (selectedItem ? selectedItem.label || '' : label) | translate }}
    <rmx-icon (click)="onArrowClick($event)"
      [name]="isOpen ? 'arrow-up-s-line' : 'arrow-down-s-line'"
      class="hero-icon-gray dropdown-toggle-icon"></rmx-icon>
  </div>
  <div *ngIf="enableSearch"
    class="dropdown-toggle-search">
    <input #searchInput
      [placeholder]="label"
      name="search"
      class="mt-0"
      autocomplete="off"
      type="text"
      (keyup)="onSearch($event)"
      (click)="onSearch($event)">
    <rmx-icon (click)="toggleDropdown()"
      name="search-line"
      class="hero-icon-gray dropdown-toggle-icon"></rmx-icon>
  </div>

  <ul class="dropdown-options"
    [class.open]="isOpen">
    <perfect-scrollbar class="dropdown-perfect-scrollbar"
      [style.height.px]="areaHeight"
      [config]="{ suppressScrollX: true }">
      <div *ngIf="!enableSearch">
        <li class="dropdown-option"
          [class.selected]="isSelected(item)"
          [class.dropdown-option--disabled]="item.disabled"
          (click)="chooseOption(item)"
          *ngFor="let item of items">{{ item.label | translate }}</li>
      </div>
      <div *ngIf="enableSearch">
        <li *ngFor="let item of filteredItems"
          class="dropdown-option dropdown-option-multiple"
          [class.dropdown-option--disabled]="item.disabled">
          <label class="checkbox-wrapper">
            <input type="checkbox"
              id="checkbox"
              [disabled]="item.disabled ? true : null"
              (change)="changeCheckbox(item, $event)"
              [checked]="item.isSelected">
            <div class="checkbox-container">
              <rmx-icon name="check-line"
                class="checkbox-check-sign hero-icon-white"></rmx-icon>
            </div>
            {{ item.label | translate }}
          </label>
        </li>
      </div>
    </perfect-scrollbar>
  </ul>

</div>