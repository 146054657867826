import { Component, OnInit, Input, forwardRef, Output, EventEmitter } from '@angular/core';

import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { IHeroDropdownItem } from '@interfaces';
import { EDropdownMode } from '@enums';

const CUSTOM_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DropdownComponent),
  multi: true,
};

@Component({
  selector: 'hero-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [CUSTOM_CONTROL_VALUE_ACCESSOR],
})
export class DropdownComponent implements OnInit, ControlValueAccessor {
  @Input() set disabled(disabled: boolean) {
    this.isDisabled = disabled;
  }
  @Input() areaHeight = '400';
  @Input() className = '';
  @Input() items: IHeroDropdownItem[] = [];
  @Input() selectedItem: IHeroDropdownItem = null;
  @Input() label: string;
  @Input() enableSearch = false;
  @Input() mode: EDropdownMode = EDropdownMode.SINGLE_SELECTION;
  @Input() closeOnSelect = true;

  @Output() selectItem = new EventEmitter<IHeroDropdownItem>();

  searchResults: IHeroDropdownItem[];
  searchPhrase: string;
  isOpen = false;
  isDisabled = false;

  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (arg: any) => void = () => {};

  get filteredItems(): IHeroDropdownItem[] {
    if (!this.searchPhrase) {
      return this.items;
    }
    return this.items.filter(item => {
      return item.label.toLowerCase().includes(this.searchPhrase.toLocaleLowerCase());
    });
  }

  constructor() {}

  ngOnInit() {
    this.searchResults = [...this.items];
  }

  onArrowClick(event) {
    event.stopPropagation();
    this.toggleDropdown();
  }

  toggleDropdown() {
    if (this.isDisabled) {
      this.isOpen = false;
      return;
    }
    this.isOpen = !this.isOpen;
  }

  chooseOption(item: IHeroDropdownItem) {
    if (item.disabled) {
      return;
    }

    this.closeAfterSelection();

    this.selectedItem = item;

    if (this.selectItem) {
      this.selectItem.emit(item);
    }
    this.onTouchedCallback();
    this.onChangeCallback(item);
  }

  onSearch(e: any) {
    this.isOpen = true;
    this.searchPhrase = e.target.value;
  }

  setItemSelected(item: IHeroDropdownItem) {
    (this.items || []).forEach(obj => {
      if (obj.value === item.value) {
        this.selectedItem = item;
        obj.isSelected = true;
      }
    });
  }

  changeCheckbox(changedItem, e) {
    if (e && e.currentTarget) {
      changedItem.isSelected = e.currentTarget.checked || false;
      if (this.selectItem) {
        this.selectItem.emit(changedItem);
      }
      if (this.mode === EDropdownMode.MULTIPLE_SELECTION) {
        this.onChangeCallback(this.items.filter(item => item.isSelected));
      }
      this.onTouchedCallback();
      this.closeAfterSelection();
    }
  }

  writeValue(items: Array<IHeroDropdownItem> | IHeroDropdownItem) {
    if (!items) {
      return;
    }
    if (!(items instanceof Array)) {
      items = [items];
    }

    this.unselectAllItems();

    items.forEach(item => {
      this.setItemSelected(item);
    });

    if (this.mode === EDropdownMode.MULTIPLE_SELECTION) {
      this.onChangeCallback(items);
    } else {
      this.onChangeCallback(items[0]);
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  onBlur() {
    this.onTouchedCallback();
  }

  isSelected(item: IHeroDropdownItem) {
    return this.selectedItem && this.selectedItem.value === item.value;
  }

  private unselectAllItems() {
    this.items = this.items.map(item => {
      return {
        ...item,
        isSelected: false,
      };
    });
  }

  private closeAfterSelection() {
    if (this.closeOnSelect) {
      this.isOpen = false;
    }
  }
}
