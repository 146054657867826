import { Injectable, Injector } from '@angular/core';

import { EUserRole } from '@common/enums';
import { RoleGuard } from '@common/services/guards/role.guard';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard extends RoleGuard {
  permittedRole: EUserRole = EUserRole.USER;

  constructor(protected injector: Injector) {
    super(injector);
  }
}
