import { Component, Input, OnInit } from '@angular/core';

import { StoreService } from '@common/core/services';

import { IHeroDropdownItem } from '@interfaces';

@Component({
  selector: 'hero-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() accountMenuItems: IHeroDropdownItem[] = [];

  isOpen = false;

  get user() {
    return this.storeService.store.user;
  }

  constructor(public storeService: StoreService) {}

  ngOnInit(): void {}

  toggleDropdown(event) {
    event.preventDefault();
    this.isOpen = !this.isOpen;
  }

  dropdownClickHandler(item: IHeroDropdownItem) {
    if (item.func) {
      item.func();
    }
  }

  trackByFn(index: number) {
    return index;
  }
}
