import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PortalModule } from '@angular/cdk/portal';

import { AdminSharedModule } from '@shared/shared.module';
import { CommonCoreModule } from '@common/core/common-core.module';

import { throwIfAlreadyLoaded } from './module-config';

import { DesktopAdminLayoutComponent } from './components/desktop-admin-layout/desktop-admin-layout.component';
import { AdminWrapperComponent } from './components/admin-wrapper/admin-wrapper.component';

const EXPORTS_COMPONENTS = [];
const COMPONENTS = [DesktopAdminLayoutComponent, AdminWrapperComponent];

@NgModule({
  imports: [CommonCoreModule, RouterModule, AdminSharedModule.forRoot(), PortalModule],
  exports: [...EXPORTS_COMPONENTS],
  declarations: [...EXPORTS_COMPONENTS, ...COMPONENTS],
  providers: [],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
