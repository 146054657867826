<a [href]="url"
  target="_blank"
  *ngIf="metadata && (image || metadata?.ogSiteName || metadata?.ogDescription)"
  class="hero-summary-landing-page mt-3">
  <img *ngIf="image"
    [src]="image.src"
    alt="">
  <div class="hero-summary-landing-page__description">
    <h4 class="hero-summary-landing-page__title">{{ (metadata?.ogSiteName || '') | truncate: 35 }}</h4>
    <p class="hero-summary-landing-page__paragraph">{{ (metadata?.ogDescription || '') | truncate: 85 }}</p>
  </div>
</a>
