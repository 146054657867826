import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hero-admin-wrapper',
  templateUrl: './admin-wrapper.component.html',
  styleUrls: ['./admin-wrapper.component.scss'],
})
export class AdminWrapperComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
