import { BitfPaginationApiRequestPart } from '@bitf/core/api-call-state/bitf-pagination-api-request-part';

import {
  CampaignStatusApiCallStatePart,
  ChannelApiRequestPart,
  DateRangeApiRequestPart,
  SortApiRequestPart,
  TimeUnitApiRequestPart,
} from '@common/core/api-call-state';
import { DefaultApiCallStateMapper } from '@common/core/api-call-state';

import { EApiCallStateNames, EApiRequestPartKeys, EBitfApiSortDirection } from '@enums';
import { IBitfApiCallState } from '@interfaces';

import { CampaignsFilersApiCallStateRequestPart } from './campaigns-filers-api-call-state-part.builder';

export const apiCallStateConfig = [
  {
    apiCallStateName: EApiCallStateNames.DEMO,
    apiRequest: {
      // NOTE passing page and size from here is still valid if BitfPaginationApiRequestPart is not used
      // page: 1,
      // size: 2,
      sorting: [{ property: 'firstName', direction: 'ASC' }],
    },
    requestParts: [new BitfPaginationApiRequestPart(EApiRequestPartKeys.PAGINATION, { page: 1, size: 10 })],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.CAMPAIGNS,
    apiRequest: {},
    requestParts: [
      new SortApiRequestPart([{ direction: EBitfApiSortDirection.ASC, property: 'name' }]),
      new BitfPaginationApiRequestPart(EApiRequestPartKeys.PAGINATION, { page: 1, size: 10 }),
      new CampaignsFilersApiCallStateRequestPart(),
      new CampaignStatusApiCallStatePart(),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.CUSTOMERS,
    apiRequest: {
      // NOTE passing page and size from here is still valid if BitfPaginationApiRequestPart is not used
      // page: 1,
      // size: 2,
      sorting: [{ property: 'companyName', direction: 'ASC' }],
    },
    requestParts: [new BitfPaginationApiRequestPart(EApiRequestPartKeys.PAGINATION, { page: 1, size: 10 })],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.INSIGHTS_CHART,
    apiRequest: {
      // NOTE passing page and size from here is still valid if BitfPaginationApiRequestPart is not used
      // page: 1,
      // size: 2,
    },
    requestParts: [new DateRangeApiRequestPart(), new TimeUnitApiRequestPart(), new ChannelApiRequestPart()],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.INSIGHTS_CARDS,
    apiRequest: {
      // NOTE passing page and size from here is still valid if BitfPaginationApiRequestPart is not used
      // page: 1,
      // size: 2,
    },
    requestParts: [new DateRangeApiRequestPart(), new ChannelApiRequestPart()],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.INSIGHTS_LIST,
    apiRequest: {
      // NOTE passing page and size from here is still valid if BitfPaginationApiRequestPart is not used
      // page: 1,
      // size: 2,
      sorting: [{ property: 'companyName', direction: 'ASC' }],
    },
    requestParts: [new BitfPaginationApiRequestPart(EApiRequestPartKeys.PAGINATION, { page: 1, size: 10 })],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.INSIGHTS_CAMPAIGNS,
    apiRequest: {},
    requestParts: [
      new SortApiRequestPart([{ direction: EBitfApiSortDirection.ASC, property: 'name' }]),
      new BitfPaginationApiRequestPart(EApiRequestPartKeys.PAGINATION, { page: 1, size: 5 }),
      new CampaignsFilersApiCallStateRequestPart(),
      new CampaignStatusApiCallStatePart(),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.INSIGHTS_CHART_CAMPAIGNS,
    apiRequest: {
      // NOTE passing page and size from here is still valid if BitfPaginationApiRequestPart is not used
      // page: 1,
      // size: 2,
    },
    requestParts: [new DateRangeApiRequestPart(), new TimeUnitApiRequestPart()],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
];
