<div class="hero-radio-box">
  <input type="radio"
    [id]="value"
    [name]="name"
    [value]="value" />
  <div class="hero-radio-container">
    <div class="hero-radio-checkmark"></div>
  </div>
  <label [for]="value"
    class="hero-radio-box-label">
    <span class="hero-radio-box-label__title">{{ label }}</span>
    {{ description }}
  </label>
</div>
