import { Injectable, Injector } from '@angular/core';

import { BitfApiService } from '@bitf/services/api/bitf-api.service';

import { Statistics } from '@common/core/models';
import { EChannelName } from '@common/enums';
import { IBitfApiRequest } from '@common/interfaces';

@Injectable({
  providedIn: 'root',
})
export class StatisticsService extends BitfApiService {
  constructor(public injector: Injector) {
    super('statistics', injector);
  }

  getSummary(request: IBitfApiRequest, channel: EChannelName) {
    return this.request<Statistics>({
      method: 'GET',
      path: channel === EChannelName.ALL ? '/global-summary' : '/summary',
      ...request,
    });
  }

  getDividedByTimeUnit(request: IBitfApiRequest, channel: EChannelName) {
    return this.request<Statistics>({
      method: 'GET',
      path: channel === EChannelName.ALL ? '/global-divided-by-time-unit' : '/divided-by-time-unit',
      ...request,
    });
  }
}
