import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { catchError, map } from 'rxjs/operators';
import { TimeoutError } from 'rxjs';

import { BitfAuthService } from '@bitf/core/services/auth/bitf-auth.service';

import { IAppLogin, IBitfApiResponse, IBitfTokenMetadata } from '@common/interfaces';

import { ApiAuthService, LoaderService } from '@services';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BitfAuthService<IAppLogin, IBitfTokenMetadata> {
  tokenAlertTresholdMinutes = 5;
  constructor(
    protected injector: Injector,
    private authApiService: ApiAuthService,
    private loader: LoaderService
  ) {
    super(injector);
  }

  handleAuthentication() {}

  decodeToken(login: IAppLogin): IBitfTokenMetadata {
    const {
      user: { token },
      expiresAt,
    } = login;
    return {
      expiresAt,
      token,
    };
  }

  // isTokenValid(): boolean {
  //   return !this.isTokenExpired() || !this.authTokenMetaData.token;
  // }

  async renewToken(): Promise<IAppLogin> {
    return new Promise<IAppLogin>((resolve, reject) => {
      if (!this.isTokenValid()) {
        reject();
        return;
      }

      this.authApiService
        .renewToken()
        .pipe(
          catchError(error => {
            reject();
            throw error;
          })
        )
        .subscribe(response => {
          this.signIn(response.content);
          resolve(response.content);
        });
    });
  }

  loginUserWithToken(next?: ActivatedRouteSnapshot) {
    return this.authApiService.renewToken().pipe(
      map((response: IBitfApiResponse<IAppLogin>) => {
        this.setUser(response.content.user);
        if (!response.content) {
          throw new Error('Empty user!');
        }
        return true;
      }),
      catchError(error => {
        if (!(error instanceof TimeoutError)) {
          this.signOut();
        }
        throw error;
      })
    );
  }

  signOut() {
    super.signOut();
    // NOTE: this will be useful with cookies
    // this.loader.show();
    // this.authApiService.logout().subscribe(() => {
    //   super.signOut();
    // });
  }
}
