<div class="hero-datepicker">
  {{ label }}
  <div class="hero-datepicker__dates">
    <div #datepicker
      class="hero-datepicker__trigger"></div>
    <div class="hero-datepicker__date"
      [class.w-100]="!range"
      [class.w-50]="range">
      <div class="hero-datepicker__date-icon">
        <rmx-icon name="calendar-line"
          class="hero-icon-blue-gray-400"></rmx-icon>
      </div>
      <div class="drv-datepicker-field">
        {{ dates.startDate | heroDate }}
      </div>
    </div>
    <div class="hero-datepicker__date w-50">
      <div class="hero-datepicker__date-icon">
        <rmx-icon name="calendar-line"
          class="hero-icon-blue-gray-400"></rmx-icon>
      </div>
      <div class="drv-datepicker-field">
        {{ dates.endDate | heroDate }}
      </div>
    </div>
  </div>
  <!-- <div class="hero-datepicker__wrapper">
    <air-datepicker *ngIf="isOpen"
      [airOptions]="options"
      [airDate]="dates.startDate"
      [airEndDate]="dates.endDate"
      (airEndChange)="onEndDateChanged($event)"
      (airChange)="onStartDateChanged($event)"
      [range]="range"></air-datepicker>
  </div> -->
</div>

<!-- (airChange)="onDateChange($event)" -->
<!--  [(airDate)]="currentDate"-->