import { EUserRole, EUserScope } from '@common/enums';

import { SuperModel } from './super-model.model';

export class User extends SuperModel {
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  password: string;
  token?: string;
  role: EUserRole;
  scope?: EUserScope;

  constructor(data = {}) {
    super(data);
  }

  get serialised() {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      companyName: this.companyName,
      email: this.email,
      password: this.password,
      role: this.role,
      scope: this.scope,
    };
  }

  get canViewLogs() {
    return true;
  }
}
