<div class="hero-panel-tabs">
  <ul class="hero-panel-tabs-list">
    <li class="hero-panel-tabs-item"
      [ngClass]="className"
      [class.selected]="isSelected(item) || item.isSelected"
      [class.disabled]="item.disabled"
      *ngFor="let item of items; trackBy: trackByFn"
      (click)="onSelect(item)">
      {{ item.label | translate }} <span *ngIf="showCounter">({{ item.counter || '0' }})</span>
    </li>
  </ul>
</div>
