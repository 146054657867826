import { EUserScope } from '@common/enums';
import { SuperModel } from './super-model.model';

import { User } from './user.model';

export class Customer extends SuperModel {
  companyName: string;
  users?: User[];
  ownerFirstName: string;
  ownerLastName: string;

  constructor(data: any = {}) {
    super(data);

    if (data.users) {
      this.users = data.users.map(user => new User(user));
    }
    this.ownerFirstName = data.ownerfirstname;
    this.ownerLastName = data.ownerlastname;
  }

  get serialised() {
    return {
      companyName: this.companyName,
    };
  }
}
