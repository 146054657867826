import { ChartConfiguration, ChartDataset } from 'chart.js';

import { EColorsRGBA } from '@common/enums';

const lineChartConfig: ChartConfiguration['options'] = {
  font: {
    family: 'ProximaNova',
  },
  // devicePixelRatio: 1,
  maintainAspectRatio: true,
  responsive: true,
  aspectRatio: 8 / 3,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      mode: 'index',
      intersect: false,
      titleFont: {
        family: 'ProximaNova',
        size: 13,
      },
      bodyFont: {
        family: 'ProximaNova',
        size: 13,
      },
      bodySpacing: 4,
    },
  },
  hover: {
    mode: 'nearest',
    intersect: true,
  },
  scales: {
    xAxes: {
      display: true,
      grid: { display: false },
      offset: false,
      ticks: {
        font: { family: 'ProximaNova', size: 12 },
        color: EColorsRGBA.BLUE_GRAY_400,
        padding: 15,
      },
    },
    yAxes: {
      display: true,
      grid: { color: EColorsRGBA.BLUE_GRAY_350, drawOnChartArea: true, drawTicks: false, drawBorder: false },
      ticks: {
        font: { family: 'ProximaNova', size: 12 },
        color: EColorsRGBA.BLUE_GRAY_400,
        padding: 17,
      },
      title: {
        display: false,
      },
    },
  },
};

export const chartDefaultConfig: { [key: string]: ChartConfiguration['options'] } = {
  line: lineChartConfig,
};

export const defaultLineDatasetConfig: Partial<ChartDataset<'line'>> = {
  fill: false,
  pointRadius: 0,
  borderCapStyle: 'round',
  borderJoinStyle: 'round',
  tension: 0.3,
};
