<div class="hero-connection-card">
  <div class="hero-connection-card__header">
    <h3 class="hero-connection-card__header-title">{{ title | translate }}</h3>
    <button [disabled]="!isEnabled"
      [class.btn-primary]="isEnabled"
      class="btn"
      (click)="onConnect()">{{ 'COMMON.BUTTON.CONNECT' | translate }}</button>
  </div>
  <div class="hero-connection-card__body hero-connection-card__content">
    {{ description | translate }}
    <ul *ngIf="list">
      <li *ngFor="let listItem of list; let i = index; trackBy: trackByFn">{{ listItem | translate }}</li>
    </ul>
  </div>
</div>