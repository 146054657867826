<div class="hero-chip-container">
  <ng-container *ngFor="let item of items; trackBy: trackByFn">
    <div *ngIf="item.label"
      class="hero-chip"
      [class.hero-chip-spacing]="disabledRemoveBtn"
      [class.hero-chip-rounded]="rounded">
      <span>{{ item.label | translate }}</span>
      <rmx-icon name="close-fill"
        class="close-icon"
        (click)="removeChip(item)"
        [class.d-none]="disabledRemoveBtn"></rmx-icon>
    </div>
  </ng-container>
</div>