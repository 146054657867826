import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IHeroConnectionItem } from '@interfaces';

@Component({
  selector: 'hero-connection-card',
  templateUrl: './connection-card.component.html',
  styleUrls: ['./connection-card.component.scss'],
})
export class ConnectionCardComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() list: string[] = [];
  @Input() isConnected: boolean;
  @Input() isEnabled = false;
  @Output() connectClick = new EventEmitter<IHeroConnectionItem>();

  constructor() {}

  ngOnInit(): void {}

  onConnect() {
    this.connectClick.emit();
  }

  trackByFn(index) {
    return index;
  }
}
