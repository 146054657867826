import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DesktopLayoutComponent, NotFoundComponent, MobileLayoutComponent } from '@common/core/components';
import { AuthGuard } from '@common/core/services/guards';
import { DesktopAdminLayoutComponent } from '@admin/core/components';

import { AdminGuard } from '@services';
import { AdminWrapperComponent } from './core/components/admin-wrapper/admin-wrapper.component';

const sharedRoutes: Routes = [
  {
    path: 'ai-settings',
    loadChildren: () => import('./modules/ai-settings/ai-settings.module').then(m => m.AiSettingsModule),
    canActivate: [AuthGuard, AdminGuard],
  },
  {
    path: 'clients',
    loadChildren: () => import('./modules/clients/clients.module').then(m => m.ClientsModule),
    canActivate: [AuthGuard, AdminGuard],
  },
  {
    path: 'campaigns',
    loadChildren: () => import('./modules/campaigns/campaigns.module').then(m => m.CampaignsModule),
    canActivate: [AuthGuard, AdminGuard],
  },
  {
    path: 'insights',
    loadChildren: () => import('./modules/insights/insights.module').then(m => m.InsightsModule),
    canActivate: [AuthGuard, AdminGuard],
  },
  // {
  //   path: 'users',
  //   loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
  //   canActivate: [AuthGuard, AdminGuard],
  // },
  // {
  //   path: 'dashboard',
  //   loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
  //   canActivate: [AuthGuard, AdminGuard],
  // },
  {
    path: 'demo',
    loadChildren: () => import('./modules/demo/demo.module').then(m => m.DemoModule),
    canActivate: [AuthGuard, AdminGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('../../common/modules/protected/protected.module').then(m => m.ProtectedModule),
    canActivate: [AuthGuard, AdminGuard],
  },
  {
    path: '**',
    component: NotFoundComponent,
    canActivate: [AuthGuard, AdminGuard],
  },
];

const routes: Routes = [
  {
    path: '',
    component: AdminWrapperComponent,
    children: [
      { path: '', redirectTo: 'campaigns', pathMatch: 'full' },
      {
        path: '',
        loadChildren: () => import('../../common/modules/public/public.module').then(m => m.PublicModule),
        data: { disableSignUp: true },
      },

      // {
      //   path: 'm',
      //   component: DesktopAdminLayoutComponent,
      //   children: [...sharedRoutes],
      // },
      {
        path: '',
        component: DesktopAdminLayoutComponent,
        children: [...sharedRoutes],
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
