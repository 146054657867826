export const hideTooltipPlugin = {
  id: 'hide_tooltip_plugin',
  beforeDraw: chartInstance => {
    const tooltipsToDisplay = [1, 2, 3, 4, 5];
    const active = chartInstance.tooltip.dataPoints && chartInstance.tooltip.dataPoints[0].datasetIndex;
    if (!tooltipsToDisplay.includes(active)) {
      chartInstance.tooltip.opacity = 0;
    }
  },
};
