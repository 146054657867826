import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { BitfApiService } from '@bitf/services/api/bitf-api.service';

import { IBitfApiAction, IBitfApiResponse } from '@interfaces';
import { LoginResult } from '@models';

@Injectable({
  providedIn: 'root',
})
export class ApiAuthService extends BitfApiService {
  constructor(public injector: Injector) {
    super('auth', injector);
  }

  login(email: string, password: string): Observable<IBitfApiResponse<LoginResult>> {
    return super.request<LoginResult>({
      path: '/login',
      method: 'POST',
      isBodyRaw: true,
      modelMapper: 'login-result',
      body: { email, password },
    });
  }

  renewToken(): Observable<IBitfApiResponse<LoginResult>> {
    return super.request<LoginResult>({
      path: '/renew-token',
      method: 'POST',
      isBodyRaw: true,
      modelMapper: 'login-result',
    });
  }

  logout(): Observable<IBitfApiResponse<IBitfApiAction>> {
    return super.request<IBitfApiAction>({
      path: '/logout',
      method: 'POST',
      modelMapper: 'action',
    });
  }

  resetPassword(
    newPassword: string,
    token: string,
    timestamp: number
  ): Observable<IBitfApiResponse<IBitfApiAction>> {
    return super.request<IBitfApiAction>({
      path: '/reset-password',
      method: 'POST',
      modelMapper: 'action',
      body: {
        newPassword,
        token,
        timestamp,
      },
      isBodyRaw: true,
    });
  }

  sendResetPasswordLink(email: string): Observable<IBitfApiResponse<IBitfApiAction>> {
    return super.request<IBitfApiAction>({
      path: '/send-reset-password-link',
      method: 'POST',
      modelMapper: 'action',
      body: {
        email,
      },
      isBodyRaw: true,
    });
  }
}
