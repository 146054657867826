import {
  Component,
  OnInit,
  Input,
  forwardRef,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { AirOptions } from 'angular2-air-datepicker';

import Litepicker from 'litepicker';
import 'litepicker/dist/plugins/ranges';

import { IDatepickerDates } from './datapicker.interface';
import { arrowLeft, arrowRight } from './datepicker.constants';

const CUSTOM_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DatepickerComponent),
  multi: true,
};

@Component({
  selector: 'hero-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [CUSTOM_CONTROL_VALUE_ACCESSOR],
})
export class DatepickerComponent implements OnInit, OnChanges, ControlValueAccessor {
  @ViewChild('datepicker', { static: true }) datepicker: ElementRef;

  @Input() minDate: Date | number = null;
  @Input() maxDate: Date | number = null;
  @Input() label: string;
  @Input() range = false;

  private dayTimestamp = 24 * 60 * 60 * 1000;

  dates: IDatepickerDates = {
    startDate: new Date(),
    endDate: null,
  };

  options: AirOptions;
  isOpen = false;

  litepicker: Litepicker;

  constructor() {}

  ngOnInit() {
    this.initOptions();
    this.onChangeCallback(this.dates);
    this.initLitepicker();
  }

  initLitepicker() {
    this.litepicker = new Litepicker({
      element: this.datepicker.nativeElement,
      singleMode: false,
      numberOfMonths: 2,
      numberOfColumns: 2,
      autoApply: false,
      resetButton: false,
      startDate: this.dates.startDate,
      endDate: this.dates.endDate,
      buttonText: {
        apply: 'Submit',
        cancel: 'Reset',
        previousMonth: arrowLeft,
        nextMonth: arrowRight,
        reset: '',
      },
      plugins: ['ranges'],
      ranges: {
        position: 'right',
        customRanges: this.getCustomRanges(),
      },
      setup: picker => {
        picker.on('button:apply', (date1, date2) => {
          this.dates = {
            startDate: date1.dateInstance,
            endDate: date2.dateInstance,
          };
          this.checkDatesRange();
          this.onChangeCallback(this.dates);
        });
      },
    });
  }

  initOptions() {
    this.options = {
      enabledDateRanges: [],
      isDisabled: date => false,
      range: this.range,
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.options) {
      return;
    }
    this.options.enabledDateRanges = [];
    if (this.minDate && this.maxDate) {
      this.options.enabledDateRanges = [
        {
          start: new Date(this.getDateTime(this.minDate)),
          end: new Date(this.getDateTime(this.maxDate)),
        },
      ];
    } else if (this.minDate) {
      this.options.enabledDateRanges = [
        {
          start: new Date(this.getDateTime(this.minDate)),
          end: new Date(Date.now() + 9999 * this.dayTimestamp),
        },
      ];
    } else if (this.maxDate) {
      this.options.enabledDateRanges = [
        {
          start: new Date(Date.now() - 9999 * this.dayTimestamp),
          end: new Date(this.getDateTime(this.maxDate)),
        },
      ];
    }
  }

  getDateTime(date) {
    if (date instanceof Date) {
      return date.getTime();
    }
    return date;
  }

  onStartDateChanged(date) {
    if (!this.range) {
      this.isOpen = false;
    }

    if (date instanceof Date) {
      date.setHours(12, 0);
    }

    this.dates = {
      ...this.dates,
      startDate: date,
    };
    this.checkDatesRange();
    this.onChangeCallback(this.dates);
  }

  onEndDateChanged(date) {
    if (!this.range) {
      this.isOpen = false;
    }

    if (date instanceof Date) {
      date.setHours(12, 0);
    }

    this.dates = {
      ...this.dates,
      endDate: date,
    };
    this.checkDatesRange();
    this.onChangeCallback(this.dates);
  }

  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (arg: any) => void = () => {};

  private checkDatesRange() {
    const { startDate, endDate } = this.dates;
    if (!startDate || !endDate) {
      return;
    }

    if (startDate.getTime() > endDate.getTime()) {
      this.dates = {
        startDate: endDate,
        endDate: startDate,
      };
    }
  }

  getDate(day, month, year) {
    return new Date(Date.UTC(year, month - 1, day));
  }

  writeValue(dates: IDatepickerDates) {
    const { startDate, endDate } = dates;
    this.dates = {
      startDate: startDate ? this.convertDate(startDate) : null,
      endDate: endDate ? this.convertDate(endDate) : null,
    };
    this.litepicker.setDateRange(this.dates.startDate, this.dates.endDate);
    this.onChangeCallback(this.dates);
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  onBlur() {
    this.onTouchedCallback();
  }

  private convertDate(date: Date | string) {
    if (!date) {
      return null;
    }

    if (typeof date === 'string') {
      date = new Date(date);
    }

    return this.getDate(date.getDate(), date.getMonth() + 1, date.getFullYear());
  }

  private getCustomRanges(): { [key: string]: Date[] } {
    const daysNumbers = [7, 14, 30, 60, 90, 180, 365];
    const yesterdayDate = new Date();
    yesterdayDate.setDate(new Date().getDate() - 1);
    const ranges: { [key: string]: Date[] } = {
      Today: [new Date(), new Date()],
      Yesterday: [yesterdayDate, yesterdayDate],
    };
    daysNumbers.forEach(numberOfDays => {
      const startDate = new Date();
      startDate.setDate(new Date().getDate() - (numberOfDays - 1));

      const label = `Last ${numberOfDays} days`;
      ranges[label] = [startDate, new Date()];
    });
    return ranges;
  }
}
