<bitf-dnd-file (dropFiles)="onFileDropped($event)"
  *ngIf="showDndArea">
  <div class="d-flex flex-column hero-uploader justify-content-center align-items-center">
    <button class="btn btn-outline btn-outline--focused hero-uploader__choose-button"
      (click)="selectFile.click()"> {{ 'COMMON.FILE_UPLOADER.BUTTON.CHOOSE_FILES' | translate }}
    </button>
    <p class="hero-uploader__label">{{ 'COMMON.FILE_UPLOADER.DRAG_AND_DROP_HERE' | translate }}</p>
  </div>
  <input class="d-none"
    type="file"
    #selectFile
    [attr.multiple]="(maxNumberOfFiles > 1) ? '' : null"
    (change)="onFileDropped($event)" />
</bitf-dnd-file>
<ng-content></ng-content>
<hero-preview-upload-file *ngIf="files.length"
  [files]="files"
  (removeFile)="removeFile($event)"></hero-preview-upload-file>