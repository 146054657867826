import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';

import { CoreModule } from '@core/core.module';
import { AdminSharedModule } from '@shared/shared.module';

import { environment } from '@env/environment';

// tslint:disable-next-line:max-line-length
import { BitfMatOkCancelDialogComponent } from '@common/libs/bitforce/core/components/ui/ok-cancel-dialog/material/bitf-mat-ok-cancel-dialog.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent, BitfMatOkCancelDialogComponent],
  imports: [
    AppRoutingModule,
    CoreModule,
    AdminSharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.registerServiceWorker }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
