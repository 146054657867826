<header class="hero-header">
  <div class="hero-search">
    <hero-search-bar></hero-search-bar>
  </div>
  <div class="hero-user">
    <a routerLink="/notifications"
      class="hero-user__notification">
      <rmx-icon name="notification-line"></rmx-icon>
      <div class="hero-user__notification-circle"></div>
    </a>
    <div [class.open]="isOpen"
      class="hero-user__container"
      (click)="toggleDropdown($event)"
      (bitfClickOutside)="isOpen = false">
      <a href="#"
        class="hero-user__notification hero-user__notification-avatar">
        {{ (user?.firstName + ' ' + user?.lastName) | userInitials }}
      </a>
      <div class="d-flex hero-user-profile">
        <div class="hero-user-profile__info">
          <span>{{ user?.firstName }} {{ user?.lastName }}</span>
          <span class="hero-user-profile__info--group">{{ user?.companyName }}</span>
        </div>
        <rmx-icon name="arrow-down-s-line"
          class="hero-user-profile__icon"></rmx-icon>
      </div>
      <ul [class.d-none]="!isOpen"
        class="hero-user-profile__list">
        <li *ngFor="let item of accountMenuItems; let i = index; trackBy: trackByFn"
          class="hero-user-profile__list-item"
          (click)="dropdownClickHandler(item)"
          [routerLink]="item.url ? [item.url] : []"
          routerLinkActive="router-link-active">{{ item.label | translate }}</li>
      </ul>
    </div>
  </div>
</header>
