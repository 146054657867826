import * as Models from '@models';

export const modelsMap = new Map<string, any>([
  ['users', Models.User],
  ['login-result', Models.LoginResult],
  ['campaigns', Models.Campaign],
  ['redirect-url', Models.RedirectUrl],
  ['customer-adv-account', Models.CustomerAdvAccount],
  ['adv-account', Models.AdvAccount],
  ['statistics', Models.Statistics],
  ['customers', Models.Customer],
  ['campaigns-counters', Models.CampaignsCounters],
  ['campaigns-time-series', Models.CampaignsTimeSeries],
  ['subscription-plan', Models.SubscriptionPlan],
]);
