import { SuperModel } from './super-model.model';

import { User } from './user.model';

export class LoginResult extends SuperModel {
  user: User;
  expiresAt: number;

  constructor(data: any) {
    super(data);
    if (data.user) {
      this.user = new User(data.user);
    }
  }

  get serialised() {
    return {
      user: this.user,
      expiresAt: this.expiresAt,
    };
  }

  get canViewLogs() {
    return true;
  }
}
