export enum EApiCallStateNames {
  DEMO = 'DEMO',
  CAMPAIGNS = 'CAMPAIGNS',
  CUSTOMERS = 'CUSTOMERS',
  INSIGHTS_LIST = 'INSIGHTS_LIST',
  INSIGHTS_CHART = 'INSIGHTS_CHART',
  INSIGHTS_CARDS = 'INSIGHTS_CARDS',
  INSIGHTS_CAMPAIGNS = 'INSIGHTS_CAMPAIGNS',
  INSIGHTS_CHART_CAMPAIGNS = 'INSIGHTS_CHART_CAMPAIGNS',
}
