import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'hero-campaign-card',
  templateUrl: './campaign-card.component.html',
  styleUrls: ['./campaign-card.component.scss'],
})
export class CampaignCardComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() url: string;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  redirectToUrl() {
    if (this.url) {
      this.router.navigate([this.url]);
    }
  }
}
