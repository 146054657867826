import { Injectable } from '@angular/core';

import { BaseSearchService } from '@common/services/base-search.service';

import { ISearchable } from '@interfaces';
import { CustomersService } from '@services';

@Injectable({
  providedIn: 'root',
})
export class SearchService extends BaseSearchService {
  services: ISearchable[] = [];

  constructor(private customersService: CustomersService) {
    super();
    this.services.push(customersService);
  }
}
