import { Component, Input, OnInit } from '@angular/core';
import { ChartConfiguration, ChartDataset } from 'chart.js';

import { TranslateService } from '@ngx-translate/core';

import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';

import { defaultLineDatasetConfig } from '@common/shared/components/chart/chart-defaults.config';

import { EColorsRGBA } from '@enums';
import { Statistics, CampaignsTimeSeries } from '@models';

import { hideTooltipPlugin } from './hide-tooltip.plugin';
import { ITooltipItem, ITooltipItemData } from './insights-chart.interfaces';

@Component({
  selector: 'hero-insights-chart',
  templateUrl: './insights-chart.component.html',
  styleUrls: ['./insights-chart.component.scss'],
})
export class InsightsChartComponent implements OnInit {
  @Input() set statisticsByTimeUnit(statisticsByTimeUnit: Statistics) {
    this._statisticsByTimeUnit = statisticsByTimeUnit;
    this.updateChartData();
  }
  get statisticsByTimeUnit(): Statistics {
    return this._statisticsByTimeUnit;
  }

  @Input() set campaignsTimeSeries(campaignsTimeSeries: CampaignsTimeSeries[]) {
    this._campaignsTimeSeries = campaignsTimeSeries;
    this.updateChartData();
  }

  toTranslate = [
    bitfToTranslate('INSIGHTS.CHART.LABEL.INCOME'),
    bitfToTranslate('INSIGHTS.CHART.LABEL.ADVERTISING_SPEND'),
    bitfToTranslate('INSIGHTS.CHART.TOOLTIP.CAMPAIGN_START'),
    bitfToTranslate('INSIGHTS.CHART.TOOLTIP.CAMPAIGN_END'),
  ];
  translations: { [key: string]: string };

  _statisticsByTimeUnit: Statistics;
  _campaignsTimeSeries: CampaignsTimeSeries[];

  chartData: ChartConfiguration['data'];
  plugins: Array<unknown> = [hideTooltipPlugin];
  campaignsChartData: number[] = [];

  campaignsTooltipItems: { [key: string]: ITooltipItem } = {};

  chartOptions: ChartConfiguration['options'] = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'nearest',
        intersect: false,
        enabled: true,
        external: context => this.drawCampaignTooltip(context),
      },
    },
  };

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.translateService.get(this.toTranslate).subscribe(translations => {
      this.translations = translations;
      this.updateChartData();
    });
  }

  updateChartData() {
    if (
      !this.statisticsByTimeUnit ||
      !this.statisticsByTimeUnit.labels ||
      !this._campaignsTimeSeries ||
      !this._campaignsTimeSeries.length
    ) {
      return;
    }

    if (!this.translations) {
      return;
    }

    this.convertCampaignsToTooltipItems();

    this.chartData = {
      datasets: [
        {
          ...defaultLineDatasetConfig,
          ...this.getColorConfig(EColorsRGBA.ORANGE_400),
          label: this.translations['INSIGHTS.CHART.LABEL.CAMPAIGN'],
          showLine: false,
          segment: {
            borderColor: ctx => {
              return undefined;
            },
          },
          pointRadius: ctx => {
            return ctx.raw !== null ? 5 : 0;
          },
          data: this.campaignsChartData,
        },
        {
          ...defaultLineDatasetConfig,
          ...this.getColorConfig(EColorsRGBA.PURPLE_800),
          label: this.translations['INSIGHTS.CHART.LABEL.INCOME'],
          data: this.statisticsByTimeUnit.revenue as number[],
        },
      ],
      labels: this.statisticsByTimeUnit.labels,
    };

    if (this.statisticsByTimeUnit.cost && (this.statisticsByTimeUnit.cost as number[]).length) {
      this.chartData.datasets.push({
        ...defaultLineDatasetConfig,
        ...this.getColorConfig(EColorsRGBA.ORANGE_400),
        label: this.translations['INSIGHTS.CHART.LABEL.ADVERTISING_SPEND'],
        data: this.statisticsByTimeUnit.cost as number[],
      });
    }
  }

  convertCampaignsToTooltipItems() {
    this.campaignsChartData = [];
    this.campaignsTooltipItems = {};
    this._campaignsTimeSeries.forEach((campaignsTimeSeries, index) => {
      const campaignsExists =
        campaignsTimeSeries && campaignsTimeSeries.campaigns && campaignsTimeSeries.campaigns.length;

      if (!this.campaignsTooltipItems[index] && campaignsExists) {
        this.campaignsTooltipItems[index] = {
          start: { campaigns: [], title: this.translations['INSIGHTS.CHART.TOOLTIP.CAMPAIGN_START'] },
          end: { campaigns: [], title: this.translations['INSIGHTS.CHART.TOOLTIP.CAMPAIGN_END'] },
        };
      }

      if (campaignsExists) {
        campaignsTimeSeries.campaigns.forEach(campaign => {
          if (campaign.isStart) {
            this.campaignsTooltipItems[index].start.campaigns.push(campaign);
          } else {
            this.campaignsTooltipItems[index].end.campaigns.push(campaign);
          }
        });
      }

      this.campaignsChartData.push(campaignsExists ? this.statisticsByTimeUnit.cost[index] : null);
    });
  }

  private getColorConfig(color: EColorsRGBA): Partial<ChartDataset<'line'>> {
    return {
      backgroundColor: color,
      borderColor: color,
      pointBackgroundColor: color,
      pointBorderColor: color,
      pointHoverBackgroundColor: color,
      pointHoverBorderColor: color,
    };
  }

  private drawCampaignTooltip(context) {
    let tooltipEl = document.getElementById('heroInsightsTooltip');

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'heroInsightsTooltip';
      tooltipEl.innerHTML = '<div class="hero-insights-tooltip__body"></div>';
      document.body.appendChild(tooltipEl);
    }

    const tooltipModel = context.tooltip;

    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add('no-transform');
    }

    const campaignsDataPoint = tooltipModel.dataPoints[0];
    let innerHtml = '';

    if (
      campaignsDataPoint &&
      campaignsDataPoint.datasetIndex === 0 &&
      this.campaignsTooltipItems[campaignsDataPoint.dataIndex]
    ) {
      const tooltipData = this.campaignsTooltipItems[campaignsDataPoint.dataIndex];

      innerHtml += this.generateTooltipContent(tooltipData.start);
      innerHtml += this.generateTooltipContent(tooltipData.end);

      tooltipEl.style.display = 'block';
    } else {
      tooltipEl.style.display = 'none';
    }

    const container = tooltipEl.querySelector('.hero-insights-tooltip__body');
    container.innerHTML = innerHtml;

    const position = context.chart.canvas.getBoundingClientRect();

    tooltipEl.style.opacity = '1';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
    tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
    tooltipEl.style.pointerEvents = 'none';
  }

  private generateTooltipContent(tooltipItemData: ITooltipItemData) {
    let html = '';

    if (tooltipItemData.campaigns.length) {
      html = `
          <h3 class="hero-insights-tooltip__title">${tooltipItemData.title}</h3>
          `;
      html += this.mapCampaignsToTooltip(tooltipItemData);
    }
    return html;
  }

  private mapCampaignsToTooltip(data: ITooltipItemData) {
    return data.campaigns
      .map(campaign => {
        return `<p class="hero-insights-tooltip__description">${campaign.name}</p>`;
      })
      .join('');
  }
}
