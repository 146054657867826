<button class="hero-modal-close">
  <rmx-icon name="close-line"
    (click)="close()"></rmx-icon>
</button>
<h1 class="hero-modal-title">{{ title | translate }}</h1>
<div class="hero-modal-content hero-ok-cancel__content">
  <p>{{ message | translate }}</p>
</div>

<div class="hero-modal-actions">
  <button (click)="onCancel()"
    [class]="noButtonClass">{{ cancelText | translate }}</button>
  <button (click)="onOk()"
    [class]="yesButtonClass">{{ okText | translate }}</button>
</div>
