import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';

import { IApiQueryPart, IHeroDropdownItem } from '@interfaces';
import { EApiQueryPartOperator, EApiRequestPartKeys } from '@enums';
import { EBitfApiRequestPartsNames } from '@enums';

export class CampaignsFilersApiCallStateRequestPart extends BitfApiRequestPart {
  constructor() {
    super(EApiRequestPartKeys.CAMPAIGNS_FILTERS, EBitfApiRequestPartsNames.QUERY, {
      formValue: {},
    });
  }

  build(): void {
    this.part = undefined;
    if (!this.formValue) {
      return;
    }

    const parts: Array<IApiQueryPart> = new Array<IApiQueryPart>(
      this.buildCustomersQueryPart(),
      this.buildObjectivesQueryPart()
    ).filter(queryPart => queryPart);

    this.part = parts;
  }

  private buildCustomersQueryPart(): IApiQueryPart {
    const customersIds = this.getValuesOfDropdownItems(this.formValue.customers as IHeroDropdownItem[]);

    if (!customersIds) {
      return;
    }

    return {
      operator: EApiQueryPartOperator.IN,
      property: 'customerId',
      value: customersIds,
    };
  }

  private buildObjectivesQueryPart(): IApiQueryPart {
    const objectivesIds = this.getValuesOfDropdownItems(this.formValue.objectives as IHeroDropdownItem[]);

    if (!objectivesIds) {
      return;
    }

    return {
      operator: EApiQueryPartOperator.IN,
      property: 'objective',
      value: objectivesIds,
    };
  }

  private getValuesOfDropdownItems(dropdownItems: IHeroDropdownItem[]) {
    return dropdownItems && dropdownItems.length ? dropdownItems.map(item => item.value) : null;
  }
}
