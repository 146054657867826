import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';
import { EApiRequestPartKeys } from '@enums';
import { EBitfApiRequestPartsNames } from '@enums';
import { IBitfApiSorting } from '@web/interfaces';

export class SortApiRequestPart extends BitfApiRequestPart {
  constructor(defaultSorting: Array<IBitfApiSorting> = []) {
    super(EApiRequestPartKeys.SORTING, EBitfApiRequestPartsNames.SORTING);
    if (defaultSorting && defaultSorting.length) {
      this.formValue = {
        sorting: defaultSorting,
      };
    }
  }

  build(): void {
    this.part = undefined;
    if (!this.formValue.sorting) {
      return;
    }
    this.part = this.formValue.sorting;
  }
}
