import { Injectable, Injector } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { BitfApiService } from '@bitf/services/api/bitf-api.service';

import { AdvAccount, RedirectUrl } from '@models';
import { EChannelName } from '@enums';
import { IConnectionData } from '@common/interfaces';

import { StoreService } from '../../services/store.service';

@Injectable({
  providedIn: 'root',
})
export class ConnectorsService extends BitfApiService {
  constructor(public injector: Injector, private storeService: StoreService) {
    super('connectors', injector);
  }

  getRedirectUrl(channel: EChannelName) {
    return this.request<RedirectUrl>({
      method: 'GET',
      path: '/redirect-url',
      modelMapper: 'redirect-url',
      queryParams: {
        channel,
      },
    });
  }

  connect(body: IConnectionData) {
    return this.request<RedirectUrl>({
      method: 'POST',
      path: '/connection',
      modelMapper: 'action',
      body,
      isBodyRaw: true,
    });
  }

  getAccounts(channel: EChannelName, update = false): Observable<AdvAccount[]> {
    if (!this.storeService.store.accounts) {
      this.storeService.store.accounts = {};
    }
    if (this.storeService.store.accounts[channel] && !update) {
      return of(this.storeService.store.accounts[channel]);
    }

    return this.request<AdvAccount[]>({
      method: 'GET',
      path: '/accounts',
      modelMapper: 'adv-account',
      queryParams: { channel },
    }).pipe(
      map(apiResponse => {
        this.storeService.setStore(store => {
          store.accounts[channel] = apiResponse.content;
        });
        return apiResponse.content;
      })
    );
  }

  disconnect(channel) {
    return this.request<AdvAccount[]>({
      method: 'DELETE',
      path: '/disconnect',
      modelMapper: 'action',
      queryParams: { channel },
    });
  }

  clearAccountsCache(channel: EChannelName) {
    if (!this.storeService.store.accounts) {
      return;
    }
    this.storeService.setStore(store => {
      store.accounts[channel] = undefined;
    });
  }
}
